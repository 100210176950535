<template>
  <g>
    <svg:style>
      .cls-10, .cls-11, .cls-3, .cls-4, .cls-5, .cls-6, .cls-7, .cls-8, {
        stroke: #aeaeae;
        stroke-miterlimit: 10;
        stroke-width: 0.25px;
      }

      .cls-3 {
        fill: url(#inox-gradient);
      }

      .cls-4 {
        fill: url(#inox-gradient-2);
      }

      .cls-5 {
        fill: url(#inox-gradient-3);
      }

      .cls-6 {
        fill: url(#inox-gradient-4);
      }

      .cls-7 {
        fill: url(#inox-gradient-5);
      }

      .cls-8 {
        fill: url(#glass-pattern);
      }

      .cls-10 {
        fill: url(#inox-gradient-6);
      }

      .cls-11 {
        fill: url(#glass-pattern);
      }
    </svg:style>
    <linearGradient id="inox-gradient" 
      :x1="doorLeftWidth1 + 46.72" 
      :y1="doorTopHeight1 + 176.91" 
      :x2="doorLeftWidth1 + 74.96" 
      :y2="doorTopHeight1 + 176.91" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0.01" stop-color="#babbbc"/>
      <stop offset="0.29" stop-color="#989a9e"/>
      <stop offset="0.65" stop-color="#bdbdc1"/>
      <stop offset="0.87" stop-color="#9d9fa2"/>
      <stop offset="1" stop-color="#a6a8aa"/>
      <stop offset="1" stop-color="#c2c5c9"/>
    </linearGradient>
    <linearGradient id="inox-gradient-2" data-name="inox" 
      :x1="doorLeftWidth1 + 43.91" 
      :y1="doorTopHeight1 + 56.92" 
      :x2="doorLeftWidth1 + 53.8" 
      :y2="doorTopHeight1 + 56.92" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-3" data-name="inox" 
      :x1="doorLeftWidth1 + 87.69" 
      :y1="doorTopHeight1 + 141.63" 
      :x2="doorLeftWidth1 + 97.57" 
      :y2="doorTopHeight1 + 141.63" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-4" data-name="inox" 
      :x1="doorLeftWidth1 + 77.8" 
      :y1="doorTopHeight1 + 212.16" 
      :x2="doorLeftWidth1 + 87.69" 
      :y2="doorTopHeight1 + 212.16" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-5" data-name="inox" 
      :x1="doorLeftWidth1 + 60.86" 
      :y1="doorTopHeight1 + 238.25" 
      :x2="doorLeftWidth1 + 70.75" 
      :y2="doorTopHeight1 + 238.25" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient" 
      :x1="doorLeftWidth1 + 60.84" 
      :y1="doorTopHeight1 + 186.14" 
      :x2="doorLeftWidth1 + 60.84" 
      :y2="doorTopHeight1 + 167.79" 
      gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#f4f4f4"/>
      <stop offset="0.5" stop-color="#dee8e8"/>
      <stop offset="1" stop-color="#d1cece"/>
    </linearGradient>
    <linearGradient id="handle-gradient" 
      :x1="doorLeftWidth1 + 11.29" 
      :y1="doorTopHeight1 + 150.25" 
      :x2="doorLeftWidth1 + 16.94" 
      :y2="doorTopHeight1 + 150.25" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="inox-gradient-6" data-name="inox" 
      :x1="doorLeftWidth1 + 49.56" 
      :y1="doorTopHeight1 + 99.21" 
      :x2="doorLeftWidth1 + 91.93" 
      :y2="doorTopHeight1 + 99.21" 
      xlink:href="#inox-gradient"/>
    <linearGradient id="glass-gradient-2" data-name="glass-gradient" 
      :x1="doorLeftWidth1 + 70.74" 
      :y1="doorTopHeight1 + 115.45" 
      :x2="doorLeftWidth1 + 70.74" 
      :y2="doorTopHeight1 + 82.98" 
      xlink:href="#glass-gradient"/>
    <filter id="inset-shadow">
      <feOffset dx="0" dy="0"/>
      <feGaussianBlur stdDeviation="1"  result="offset-blur"/>
      <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
      <feFlood flood-color="black" flood-opacity="1" result="color"/>
      <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
      <feComponentTransfer in="shadow" result="shadow">
        <feFuncA type="linear" slope="1.25"/>
      </feComponentTransfer>
      <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
    </filter>
    <g id="S19">
      <polyline id="inox"
                v-if="showInox"
                data-name="inox"
                class="cls-3"
                :points="`${doorLeftWidth1 + 46.72} ${doorTopHeight1 + 162.79} ${doorLeftWidth1 + 46.72} ${doorTopHeight1 + 191.03} ${doorLeftWidth1 + 74.96} ${doorTopHeight1 + 191.03} ${doorLeftWidth1 + 74.96} ${doorTopHeight1 + 162.79} ${doorLeftWidth1 + 46.72} ${doorTopHeight1 + 162.79}`"/>
      <polyline id="inox-2"
                v-if="showInox"
                data-name="inox"
                class="cls-4"
                :points="`${doorLeftWidth1 + 53.8} ${doorTopHeight1 + 51.98} ${doorLeftWidth1 + 43.91} ${doorTopHeight1 + 51.98} ${doorLeftWidth1 + 43.91} ${doorTopHeight1 + 61.85} ${doorLeftWidth1 + 53.8} ${doorTopHeight1 + 61.85} ${doorLeftWidth1 + 53.8} ${doorTopHeight1 + 51.98}`"/>
      <polyline id="inox-3"
                v-if="showInox"
                data-name="inox"
                class="cls-5"
                :points="`${doorLeftWidth1 + 97.57} ${doorTopHeight1 + 136.69} ${doorLeftWidth1 + 87.69} ${doorTopHeight1 + 136.69} ${doorLeftWidth1 + 87.69} ${doorTopHeight1 + 146.57} ${doorLeftWidth1 + 97.57} ${doorTopHeight1 + 146.57} ${doorLeftWidth1 + 97.57} ${doorTopHeight1 + 136.69}`"/>
      <polyline id="inox-4"
                v-if="showInox"
                data-name="inox"
                class="cls-6"
                :points="`${doorLeftWidth1 + 87.69} ${doorTopHeight1 + 207.22} ${doorLeftWidth1 + 77.81} ${doorTopHeight1 + 207.22} ${doorLeftWidth1 + 77.81} ${doorTopHeight1 + 217.11} ${doorLeftWidth1 + 87.69} ${doorTopHeight1 + 217.11} ${doorLeftWidth1 + 87.69} ${doorTopHeight1 + 207.22}`"/>
      <polyline id="inox-5"
                v-if="showInox"
                data-name="inox"
                class="cls-7"
                :points="`${doorLeftWidth1 + 70.75} ${doorTopHeight1 + 233.31} ${doorLeftWidth1 + 60.86} ${doorTopHeight1 + 233.31} ${doorLeftWidth1 + 60.86} ${doorTopHeight1 + 243.19} ${doorLeftWidth1 + 70.75} ${doorTopHeight1 + 243.19} ${doorLeftWidth1 + 70.75} ${doorTopHeight1 + 233.31}`"/>
      <polyline filter="url(#inset-shadow)" id="glass"
                class="cls-8"
                :points="`${doorLeftWidth1 + 70.02} ${doorTopHeight1 + 167.79} ${doorLeftWidth1 + 51.66} ${doorTopHeight1 + 167.79} ${doorLeftWidth1 + 51.66} ${doorTopHeight1 + 186.14} ${doorLeftWidth1 + 70.02} ${doorTopHeight1 + 186.14} ${doorLeftWidth1 + 70.02} ${doorTopHeight1 + 167.79}`"/>
      <polyline id="inox-6"
                v-if="showInox"
                data-name="inox"
                class="cls-10"
                :points="`${doorLeftWidth1 + 49.56} ${doorTopHeight1 + 120.4} ${doorLeftWidth1 + 91.93} ${doorTopHeight1 + 120.4} ${doorLeftWidth1 + 91.93} ${doorTopHeight1 + 78.03} ${doorLeftWidth1 + 49.56} ${doorTopHeight1 + 78.03} ${doorLeftWidth1 + 49.56} ${doorTopHeight1 + 120.4}`"/>
      <polyline filter="url(#inset-shadow)" id="glass-2"
                data-name="glass"
                class="cls-11"
                :points="`${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 115.45} ${doorLeftWidth1 + 86.99} ${doorTopHeight1 + 115.45} ${doorLeftWidth1 + 86.99} ${doorTopHeight1 + 82.97} ${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 82.97} ${doorLeftWidth1 + 54.5} ${doorTopHeight1 + 115.45}`"/>
    </g>
  </g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
